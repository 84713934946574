<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
      <!-- {{ledger_balance}} -->
       <el-row :gutter="20">
          <!-- <el-col
              v-if="getRoleSlug === 'superadmin'"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleClientList" placeholder="Select" :disabled = true>
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"  v-if="getRoleSlug === 'superadmin'">
            <el-form-item
              :error="formErrors.user_id"
              label="User ID"
              prop="user_id"
              :label-width="formLabelWidth"
            >
              <el-select v-model="formData.user_id" placeholder="Select" :disabled = true>
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-if="getRoleSlug === 'superadmin'">
            <el-form-item
              :error="formErrors.amount"
              label="Amount"
              prop="amount"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.amount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

            
       </el-row>
        <el-row :gutter="20">
          
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.notes"
              label="Notes"
              prop="notes"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.notes" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        <!-- </el-row>
        <el-row :gutter="20"> -->
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-if="getRoleSlug !== 'superadmin'">
            <el-form-item
              :error="formErrors.amount"
              label="Amount"
              prop="amount"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.amount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.transaction_password"
              :label="passwordLabel"
              prop="transaction_password"
              :label-width="formLabelWidth"
            >
              <!-- <el-input
                v-model="formData.transaction_password"
                autocomplete="off"
              ></el-input> -->
              <el-input
                    v-model="formData.transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <el-button type="info" :disabled="loading" @click="sendChildFormData()">Credit</el-button>
        <el-button type="info" :disabled="loading" @click="sendChildFormDebitData()">Debit</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { requiredRule, numberRule } from "@/rules/all-rules";
import { requiredRule , numberRule} from "@/rules/all-rules";
import { fetchClientList } from "@/api/market-watch";
import { fetchAllAdminList, adminBrokers, adminClients } from "@/api/super-admin/admin";
import { mapGetters } from "vuex";

export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    ledger_balance: {
      type: Number,
      default: null,
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      listDataDropdownClients: {},
      listAdmins: {},
      listDataDropdownBrokers: {},
      passwordLabel: '',
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "userInfo"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Trader Fund"
        : "Edit Trader Fund";
    },
  },
  created() {
    // If broker, we will use Broker Password
    this.passwordLabel = 'Transaction Password';
    // if (this.getRoleSlug == 'broker') {
    //   this.passwordLabel = 'Broker Password';
    // } else {
    //   this.passwordLabel = 'Transaction Password';
    // }
    this.getClientList();

    if (this.getRoleSlug === 'superadmin') {
      this.getAdminList();
    }
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.amount = numberRule("amount", ["required"]);
    //this.formRules.amount = requiredRule("required");
    this.formRules.transaction_password = requiredRule("transaction_password");
  },
  methods: {
    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
        this.loading = false;
      });
    },
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      
         console.log("ppp  ",this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.formData = Object.assign(this.formData, {
            type: "customer",
          });
          this.loading = true;
          this.$emit("getChildFormData", this.formData);
          this.loading = false;

          this.formData.transaction_password = ""
          this.formData.notes = ""
          this.formData.amount = ""
        }
      });
    },
    sendChildFormDebitData() {
          this.$refs.refForm.validate((valid) => {
            if (valid) {
             
              this.formData = Object.assign(this.formData, {
                type: "customer",
              });
              this.loading = true;
              console.log("dddd  ",this.formData)
              this.$emit("getChildFormDebitData", this.formData);
              this.loading = false;

              // this.formData.transaction_password = ""
              // this.formData.notes = ""
              // this.formData.amount = ""
            }
          });
      
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleBrokerList(id) {
      this.formData.broker = ''
      adminBrokers(id)
        .then((response) => {
          if (response.data.success === true) {
            this.listDataDropdownBrokers = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
      this.getScriptList();
    },
    handleClientList(id) {
      adminClients(id)
        .then((response) => {
          if (response.data.success === true) {
            this.formData.user_id = null;
            this.listDataDropdownClients = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
      this.getScriptList();
    }
  },
};
</script>
